import { Link } from "react-router-dom";

const Navmenu = (props) => {
  return (
    <div className="fixed bottom-0 left-0 w-full h-[44px] bg-black shadow-md flex flex-row justify-center items-center py-2">
      {/* Dashboard button */}
      <Link to="/dashboard" className="flex flex-col items-center w-full h-full justify-evenly">
        <svg className="text-white fill-white text-base w-[20px] h-[20px]" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
        </svg>
      </Link>
      {/* Add */}
      <Link to="/campaign/create" className="cursor-pointer flex flex-col justify-center items-center h-[32px] aspect-square border-0 box-border rounded-3xl text-white bg-[#ff1414] outline-none">
        <svg className="text-white fill-white text-base w-[18px] h-[18px]" viewBox="0 0 448 512">
          <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
        </svg>
      </Link>

      {/* Campaigns button */}
      <Link to="/campaign/list" className="flex flex-col items-center w-full h-full justify-evenly">
        <svg className="text-white fill-white text-base w-[16px] h-[16px]" viewBox="0 0 384 512">
          <path d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S160 113.7 160 96C160 78.33 174.3 64 192 64zM272 224h-160C103.2 224 96 216.8 96 208C96 199.2 103.2 192 112 192h160C280.8 192 288 199.2 288 208S280.8 224 272 224z"></path>
        </svg>
      </Link>
    </div>
  );
};

export default Navmenu;
