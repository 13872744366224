import React from "react";
import { Form, Link } from "react-router-dom";

const Signin = (props) => {
  return (
    <div className="flex flex-col items-center justify-around w-full h-screen p-4">
      {/* Logo, Title, Description */}
      <div className="flex flex-col items-center w-full">
        {/* Logo */}
        <img className="w-[128px] h-[128px] rounded-full bg-center bg-cover bg-no-repeat" src="/images/signin.png" alt="Sign In" />
        {/* Title */}
        <div className="text-[#030303] text-2xl font-semibold leading-8 text-center">SamuraiFlow</div>
        {/* Description */}
        <p className="text-[#030303] text-base leading-6 text-center">Level up your marketing!</p>
      </div>
      {/* Form */}
      {/* TODO: Fix form */}
      <Form method="POST" action="/signin/submit" className="flex flex-col items-center w-full gap-y-3">
        {/* Email */}
        <input className="w-full py-3 px-2 border-0 box-border rounded-3xl shadow-sm bg-[#F5F5F5] text-[#898989] text-sm leading-[22px] outline-none" type="email" placeholder="Email" />
        {/* Password */}
        <input className="w-full py-3 px-2 border-0 box-border rounded-3xl shadow-sm bg-[#F5F5F5] text-[#898989] text-sm leading-[22px] outline-none" type="password" placeholder="Password" />
        {/* Forgot Password */}
        <div className="flex items-center justify-end w-full">
          <a className="text-[#898989] text-xs leading-6" href="/">
            Forgot Password?
          </a>
        </div>
        {/* Log in */}
        {/* TODO: Change back to submit button */}
        <Link to="/dashboard" className="cursor-pointer w-full py-3 px-2 border-0 box-border rounded-3xl bg-[#ff1414] text-white text-base font-semibold leading-5 outline-none">Log in</Link>
      </Form>

      {/* Horizontal separator */}
      <div className="flex items-center justify-center w-full">
        <div className="w-full h-[1px] bg-[#898989]"></div>
        <div className="mx-4 text-[#898989] text-sm">or</div>
        <div className="w-full h-[1px] bg-[#898989]"></div>
      </div>
      {/* Alternative 1-click logins */}
      <div className="flex flex-row items-center justify-center w-full gap-x-4">
        {/* Facebook */}
        <button className="cursor-pointer w-full py-3 px-2 border-0 box-border rounded-3xl bg-[#ff1414] text-white text-sm font-semibold leading-5 outline-none">Facebook</button>
        {/* Google */}
        <button className="cursor-pointer w-full py-3 px-2 border-0 box-border rounded-3xl bg-[#ff1414] text-white text-sm font-semibold leading-5 outline-none">Google</button>
      </div>
      {/* Sign up */}
      <div className="flex items-center justify-center w-full">
        <p className="text-[#898989] text-sm leading-6">Don't have an account?</p>
        <a className="text-[#ff1414] text-sm leading-6 mx-1" href="/">
          Sign up
        </a>
      </div>
    </div>
  );
};

export default Signin;
