import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import Root from './components/Root/Root';
import Signin from './components/Auth/Signin';
import Dashboard from './components/Dashboard/Dashboard';
import CampaignSingle from './components/Campaign/Single';
import Create from './components/Campaign/Create';

const router = createBrowserRouter([
  // Welcome
  {
    path: "/",
    element: <Root />,
  },
  // Sign In
  {
    path: "/signin",
    element: <Signin />,
    children: [
      // Sign in (POST)
      {
        path: "submit",
        method: "post",
        action: () => {
          return { redirect: "/dashboard" };
        },
      },
    ],
  },
  // Dashboard
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  // Campaign
  {
    path: "/campaign",
    children: [
      // Campaign detail
      {
        path: ":id",
        // Return a placeholder "Campaign detail" component
        element: <CampaignSingle />
      },
      // Campaign create
      {
        path: "create",
        // Return a placeholder "Campaign create" component
        element: <Create />,
      },
      // Campaign list
      {
        path: "list",
        // Return a placeholder "Campaign list" component
        element: <div>Campaign list</div>,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
