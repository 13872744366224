import React, { useEffect, useState } from "react";
import Navbar from "../Layout/Navbar";
import Navmenu from "../Layout/Navmenu";
import { IconClicks, IconImpressions, IconViews } from "./Icons";

const Single = (props) => {
  // Campaign results summary array with impressions, views, clicks
  const campaignResultsSummary = [
    { icon: <IconImpressions />, name: "Impresiones", value: 10000 },
    { icon: <IconViews />, name: "Views", value: 6500 },
    { icon: <IconClicks />, name: "Clicks", value: 120 },
  ];

  const tasks = [
    { name: "Subir videos de análisis", description: "Sube 4 videos de análisis según los guiones hechos a partir de la investigación de mercado", pasosCompletados: 2, pasosTotales: 4, fechaInicio: "2023-01-01", fechaFin: "2023-01-01" },
    { name: "Actualizar descripciones de las redes sociales", description: "Actualizar las descripciones de las redes sociales con las palabras clave de la investigación de mercado", pasosCompletados: 1, pasosTotales: 1, fechaInicio: "2023-01-01", fechaFin: "2023-01-01" },
  ];

  const [socialStatistics, setSocialStatistics] = useState([
    { name: "Facebook", value: 0 },
    { name: "Instagram", value: 0 },
    { name: "Twitter", value: 0 },
    { name: "Youtube", value: 0 },
    { name: "Tiktok", value: 0 },
    { name: "Twitch", value: 0 },
    { name: "Reddit", value: 0 },
    { name: "Pinterest", value: 0 },
  ]);

  useEffect(() => {
    // Assign random values to social statistics
    const updatedSocialStatistics = socialStatistics.map((socialStatistic) => {
      return { ...socialStatistic, value: Math.floor(Math.random() * 1000) };
    });

    // Select the social media with the highest value
    const selected = updatedSocialStatistics.sort((a, b) => b.value - a.value)[0].name;

    setSocialStatistics(updatedSocialStatistics);
    setSelectedSocial(selected);
  }, []);

  // Selected social
  const [selectedSocial, setSelectedSocial] = useState("");

  return (
    <>
      <Navbar />
      {/* Campaign name */}
      <div className="text-[#080a0b] text-lg font-bold leading-5 col-span-full text-center w-full">Campaña IAdelante</div>
      {/* Content */}
      <div className="flex flex-col justify-start w-full h-screen p-4 overflow-y-auto gap-y-5">
        {/* Section Title */}
        <div className="text-[#080a0b] text-base font-bold leading-5 col-span-full">Resumen métricas</div>
        {/* Campaign results summary */}
        <div className="grid items-center w-full grid-cols-3">
          {/* TODO: Find better metrics */}
          {/* Total campaigns */}
          {campaignResultsSummary.map((campaignResultSummary) => (
            <div className="flex flex-col items-center justify-center gap-y-1">
              {/* Icon */}
              {campaignResultSummary.icon}
              {/* Value */}
              <div className="text-sm font-semibold leading-5 text-center text-080a0b">{campaignResultSummary.value}</div>
              {/* Name */}
              <div className="text-[#5d5d5b] text-sm leading-4 text-center">{campaignResultSummary.name}</div>
            </div>
          ))}
        </div>

        {/* Current tasks */}
        <div className="flex flex-col items-start justify-start w-full gap-y-3">
          {/* Section Title */}
          <div className="text-[#080a0b] text-base text-center font-bold leading-5">Tareas actuales</div>
          {/* Task */}
          {tasks.map((task) => (
            <div className="w-full bg-white rounded-3xl border border-[#898989] box-border flex flex-col justify-between p-4">
              {/* Title and description */}
              <div className="flex flex-col">
                {/* Title */}
                <span className="text-base font-bold leading-5 text-080a0b">{task.name}</span>
                {/* Description */}
                <span className="text-sm leading-4 text-[#5d5d5b] mt-1">
                  {/* Trim description */}
                  {task.description.length > 32 ? task.description.substring(0, 32).trim() + "..." : task.description}
                </span>
              </div>

              {/* Progress bar */}
              <div className="flex flex-col items-start justify-center w-full mt-4">
                {/* Progress text */}
                <div className="flex flex-row items-center justify-between w-full">
                  <span className="text-sm leading-4 text-[#5d5d5b]">Progreso</span>
                  <span className="text-sm leading-4 text-[#5d5d5b]">
                    {task.pasosCompletados}/{task.pasosTotales}
                  </span>
                </div>
                {/* Progress bar */}
                <div className="w-full h-1.5 bg-[#e6e6e6] rounded-full mt-1">
                  <div
                    className="h-full bg-[#ff1414] rounded-full"
                    style={{
                      width: `${(task.pasosCompletados / task.pasosTotales) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))}

          {/* Campaign detailed statistics report */}
          {/* Section Title */}
          <div className="text-[#080a0b] text-base text-center font-bold leading-5">Estadísticas detalladas</div>
          {/* Social media statistics selector */}
          <div className="grid w-full grid-cols-4 gap-2">
            {/* Social media tabs */}
            {socialStatistics
              .sort((a, b) => b.value - a.value)
              .map((socialStatistic) => (
                <div className={`flex flex-col items-center p-2 ${socialStatistic.name === selectedSocial ? "rounded-3xl bg-[#FFd8d8]" : ""}`} onClick={() => setSelectedSocial(socialStatistic.name)}>
                  {/* Social media name */}
                  <span className="text-sm leading-4 text-[#5d5d5b]">{socialStatistic.name}</span>
                  {/* Social media value */}
                  <span className="text-base font-bold leading-5 text-080a0b">{socialStatistic.value}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Navmenu />
    </>
  );
};

export default Single;
