const Navbar = (props) => {
  return (
    <div className="sticky top-0 flex flex-row items-center justify-between w-full px-4 py-1 bg-white">
      {/* "Home" */}
      <div className="text-base font-semibold leading-5">Bienvenido ElBuenSamurai!</div>
      {/* Profile image */}
      {/* TODO: Fix stretch */}
      <img className="w-[40px] h-[40px] rounded-full bg-center bg-cover bg-no-repeat" src="/images/profile.jpeg" alt="Profile" />
    </div>
  );
};

export default Navbar;
