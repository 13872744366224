import React from "react";
import { Link } from "react-router-dom";
import Navmenu from "../Layout/Navmenu";
import Navbar from "../Layout/Navbar";

const Dashboard = (props) => {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-start w-full h-screen p-4 overflow-y-auto gap-y-5">

        {/* Main navigation: "Overview", "Campaigns" */}
        <div className="flex flex-row items-center justify-between w-full gap-x-3">
          <Link to="/dashboard" className="w-full py-3 px-2 border-0 box-border rounded-3xl bg-[#FF1414] text-white text-center text-sm font-bold leading-4 outline-none">
            Overview
          </Link>
          <Link to="/campaign/list" className="w-full py-3 px-2 border-0 box-border rounded-3xl bg-[#FF1414] text-white text-center text-sm font-bold leading-4 outline-none">
            Campaigns
          </Link>
        </div>

        {/* Campaign summary */}
        <Link to="/campaign/1"
          className="rounded-3xl border border-[#898989] box-border w-full p-4 z-10 bg-white flex flex-col gap-y-2 shadow-md">
          {/* Title and assigned users */}
          <div className="flex flex-row justify-between w-full">
            {/* Title */}
            <span className="font-bold leading-6">Campaign Progress</span>
            {/* Assigned users */}
            <div className="flex flex-row-reverse justify-center">
              {/* TODO: Iterate */}
              {/* TODO: Find new images */}
              <img className="w-[25px] h-[25px] rounded-full bg-center bg-cover bg-no-repeat -ml-2" src="/images/profile.jpeg" alt="Profile" />
              <img className="w-[25px] h-[25px] rounded-full bg-center bg-cover bg-no-repeat -ml-2" src="/images/profile.jpeg" alt="Profile" />
              <img className="w-[25px] h-[25px] rounded-full bg-center bg-cover bg-no-repeat -ml-2" src="/images/profile.jpeg" alt="Profile" />
            </div>
          </div>
          {/* Description */}
          <div className="text-sm leading-3">Next task is...</div>
          {/* Progress bar */}
          <div className="flex flex-col mt-2">
            {/* Progress number */}
            <div className="text-sm font-semibold leading-5">76%</div>
            {/* Bar */}
            <div className="relative w-full h-2 bg-[#E5E5E5] rounded-full">
              <div className="absolute top-0 left-0 h-full bg-[#FFd8d8] rounded-full" style={{ width: "76%" }}></div>
            </div>
          </div>
        </Link>

        {/* Categories */}
        <div className="flex flex-col w-full gap-y-2">
          {/* Title */}
          <div className="text-xl font-bold leading-6">Categories</div>
          {/* Categories */}
          <div className="grid grid-cols-2 gap-3 mt-2">
            {/* Cards */}
            {/* TODO: iterate */}
            {/* Repeat 4 times */}
            {Array.from({ length: 4 }).map((_, index) => (
              <div className="flex flex-col items-start justify-between p-3 w-full aspect-square rounded-3xl border border-[#898989] box-border">
                {/* Category icon + assigned users */}
                <div className="flex flex-row justify-between w-full">
                  {/* Icon */}
                  <button className="flex cursor-pointer justify-center items-center border-0 box-border rounded-3xl text-white bg-[#ff1414] outline-none w-[33px] h-[33px]">
                    <svg className="text-white fill-white w-[14px] h-[14px] text-sm" viewBox="0 0 576 512">
                      <path d="M144.3 32.04C106.9 31.29 63.7 41.44 18.6 61.29c-11.42 5.026-18.6 16.67-18.6 29.15l0 357.6c0 11.55 11.99 19.55 22.45 14.65c126.3-59.14 219.8 11 223.8 14.01C249.1 478.9 252.5 480 256 480c12.4 0 16-11.38 16-15.98V80.04c0-5.203-2.531-10.08-6.781-13.08C263.3 65.58 216.7 33.35 144.3 32.04zM557.4 61.29c-45.11-19.79-88.48-29.61-125.7-29.26c-72.44 1.312-118.1 33.55-120.9 34.92C306.5 69.96 304 74.83 304 80.04v383.1C304 468.4 307.5 480 320 480c3.484 0 6.938-1.125 9.781-3.328c3.925-3.018 97.44-73.16 223.8-14c10.46 4.896 22.45-3.105 22.45-14.65l.0001-357.6C575.1 77.97 568.8 66.31 557.4 61.29z"></path>
                    </svg>
                  </button>
                  {/* Assigned users */}
                  <div className="flex flex-row-reverse justify-center">
                    {/* TODO: Iterate */}
                    {/* TODO: Find new images */}
                    <img className="w-[25px] h-[25px] rounded-full bg-center bg-cover bg-no-repeat -ml-2" src="/images/profile.jpeg" alt="Profile" />
                    <img className="w-[25px] h-[25px] rounded-full bg-center bg-cover bg-no-repeat -ml-2" src="/images/profile.jpeg" alt="Profile" />
                  </div>
                </div>
                {/* # notifications */}
                <div className="mt-4 text-sm leading-3">3 new</div>
                {/* Title */}
                <div className="mt-1 text-base font-semibold leading-5">Campaigns</div>
                {/* Fill space */}
                <div className="flex-grow"></div>
                {/* Progress bar */}
                <div className="flex flex-row items-center justify-between w-full gap-x-2">
                  {/* Bar */}
                  <div className="relative w-full h-2 bg-[#E5E5E5] rounded-full">
                    <div className="absolute top-0 left-0 h-full bg-[#FFd8d8] rounded-full" style={{ width: "76%" }}></div>
                  </div>
                  {/* Completed tasks badge */}
                  {/* TODO: Sync with database */}
                  <div className="min-w-max w-[48px] py-1 px-2 border-0 box-border rounded-3xl bg-[#ff1414] text-white text-xs font-semibold leading-3 outline-none text-center">11/24</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Navmenu />
    </>
  );
};

export default Dashboard;
