import React from "react";
import { Link } from "react-router-dom";

const Root = (props) => {
  return (
    <div className="flex flex-col items-center justify-around w-full h-screen p-4">
      {/* Logo */}
      <img className="w-[224px] h-[224px] rounded-3xl bg-center bg-cover bg-no-repeat " src="/images/logo.png" alt="logo" />
      <div>
        {/* Title */}
        <div className="text-[#333333] text-[40px] font-bold leading-[48px] text-center">SamuraiFlow</div>
        {/* Description */}
        <p className="mt-2 text-base font-medium leading-[22px] text-center">
          Analyze your campaign data
          <br />
          and boost your marketing strategies
        </p>
      </div>
      {/* Button */}
      <Link to="/signin" className="w-[90%] cursor-pointer py-3 px-2 box-border rounded-3xl bg-[#030303] text-white text-base text-center font-normal leading-5 outline-none">
        Get Started
      </Link>
    </div>
  );
};

export default Root;
