import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Navbar from "../Layout/Navbar";
import Navmenu from "../Layout/Navmenu";

const Create = (props) => {
  // Current step
  const [currentStep, setCurrentStep] = useState(1);

  // Step 1 - Objective
  const [objective, setObjective] = useState("");
  const setObjectiveStep = (newObjective) => {
    setObjective(newObjective);
    setCurrentStep(2);
  };

  // Step 2 - Social medias
  const [socialMedias, setSocialMedias] = useState([
    { name: "Facebook", selected: false },
    { name: "Instagram", selected: false },
    { name: "Twitter", selected: false },
    { name: "Youtube", selected: false },
    { name: "Tiktok", selected: false },
    { name: "Twitch", selected: false },
    { name: "Reddit", selected: false },
    { name: "Pinterest", selected: false },
  ]);

  const setSocialStep = (newSocialMedias) => {
    setSocialMedias(newSocialMedias);
    setCurrentStep(3);
  };

  // Step 3 - Target audience
  const [targetAudienceKnown, setTargetAudienceKnown] = useState(false);
  const [targetAudience, setTargetAudience] = useState("");
  const [socialMediaContentKnown, setSocialMediaContentKnown] = useState(false);
  const [socialMediaContent, setSocialMediaContent] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");

  const setTargetAudienceStep = (
    newTargetAudienceKnown,
    newTargetAudience,
    newSocialMediaContentKnown,
    newSocialMediaContent,
    newAdditionalNotes) => {
    setTargetAudienceKnown(newTargetAudienceKnown);
    if (newTargetAudienceKnown) {
      setTargetAudience(newTargetAudience);
    }
    setSocialMediaContentKnown(newSocialMediaContentKnown);
    if (newSocialMediaContentKnown) {
      setSocialMediaContent(newSocialMediaContent);
    }
    setAdditionalNotes(newAdditionalNotes);
    
    setCurrentStep(4);
  };
  // Debug shown
  const [debug, setDebug] = useState(false);

  return (
    <div className="relative flex flex-col justify-start w-full h-full p-4 overflow-y-auto gap-y-5">
      <button className="fixed top-0 right-0 z-50 p-2 bg-white border rounded-md shadow-md" onClick={() => setDebug(!debug)}>
        Toggle debug
      </button>
      <div className={`fixed top-0 left-0 z-50 p-2 bg-white border rounded-md shadow-md ${debug ? "" : "hidden"}`}>
        Current values:
        <div>Current step: {currentStep}</div>
        <div>Objective: {objective.name}</div>
        <div>Social medias:</div>
        {socialMedias.map((socialMedia) => (
          <div key={socialMedia.name}>
            {socialMedia.name}: {socialMedia.selected ? "true" : "false"}
          </div>
        ))}
        <div>Target audience known: {targetAudienceKnown ? "true" : "false"}</div>
        <div>Target audience: {targetAudience}</div>
        <div>Social media content known: {socialMediaContentKnown ? "true" : "false"}</div>
        <div>Social media content: {socialMediaContent}</div>
        <div>Additional notes: {additionalNotes}</div>
      </div>
      {/* Render according to currentStep */}
      {currentStep === 1 && <Step1 objectiveSetter={setObjectiveStep} />}
      {currentStep === 2 && <Step2 socialSetter={setSocialStep} />}
      {currentStep === 3 && <Step3 targetAudienceSetter={setTargetAudienceStep} />}
      {currentStep === 4 && <Step4 />}
    </div>
  );
};

const Step1 = ({ objectiveSetter }) => {
  const objectives = [
    {
      name: "Reconocimiento",
      description: ["Dar a conocer mi marca", "Popularizar mi producto", "Descubrir interés en mi servicio"],
    },
    {
      name: "Interacciones",
      description: ["Conseguir seguidores", "Conseguir suscriptores", "Conseguir feedback de mi público"],
    },
    {
      name: "Clientes",
      description: ["Encontrar clientes", "Perfilar clientes potenciales", "Testear funnel de ventas"],
    },
  ];

  const handleObjectiveSet = (objective) => {
    objectiveSetter(objective);
  };

  return (
    <>
      {/* Title */}
      <div className="text-[#080a0b] text-base font-bold leading-5 col-span-full">Quiero...</div>
      {/* Select objective */}
      <div className="flex flex-col h-full gap-y-2">
        {objectives.map((objective) => (
          <div key={objective.name} className="box-border flex flex-col items-center justify-center w-full h-full p-3 border shadow-md cursor-pointer gap-y-1 rounded-3xl" onClick={handleObjectiveSet.bind(this, objective)}>
            {/* Name */}
            <div className="text-[#030303] text-lg font-light leading-4">{objective.name}</div>
            {/* Good for... */}
            <ul className="mt-2 list-disc">
              {objective.description.map((description, index) => (
                <li key={objective.name + index} className="text-[#030303] text-base font-extralight">
                  {description}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

const Step2 = ({ socialSetter }) => {
  const [socialMedias, setSocialMedias] = useState([
    { name: "Facebook", selected: false },
    { name: "Instagram", selected: false },
    { name: "Twitter", selected: false },
    { name: "Youtube", selected: false },
    { name: "Tiktok", selected: false },
    { name: "Twitch", selected: false },
    { name: "Reddit", selected: false },
    { name: "Pinterest", selected: false },
  ]);

  const handleSocialSet = (social, value) => {
    setSocialMedias(socialMedias.map((socialMedia) => (socialMedia.name === social ? { ...socialMedia, selected: value } : socialMedia)));
  };

  const handleNextStep = () => {
    console.log(socialMedias);
    socialSetter(socialMedias);
  };

  return (
    <>
      {/* Title */}
      <div className="text-[#080a0b] text-base font-bold leading-5 col-span-full">Marca las redes sociales que tu negocio/producto ya tiene</div>
      {/* Select objective */}
      <div className="grid w-full grid-cols-3 gap-4">
        {socialMedias.map((socialMedia) => (
          <div key={socialMedia.name} className="box-border flex flex-col items-center justify-center w-full h-full p-3 border shadow-sm cursor-pointer gap-y-1 rounded-3xl" onClick={handleSocialSet.bind(this, socialMedia.name, !socialMedia.selected)}>
            {/* Name */}
            <div className="text-[#030303] text-base font-light leading-4">{socialMedia.name}</div>
            {/* Social media icon */}
            <div className="w-12 h-12 bg-gray-300 rounded-full"></div>
            {/* Switch */}
            <Switch checked={socialMedia.selected} onChange={handleSocialSet.bind(this, socialMedia.name, !socialMedia.selected)} />
          </div>
        ))}
      </div>
      {/* Next step button on the bottom of the screen */}
      <div className="absolute left-0 right-0 flex flex-row items-center justify-center w-full px-4 mx-auto bottom-2">
        <button className="w-full py-2 bg-[#CC1010] rounded-3xl shadow-md" onClick={handleNextStep}>
          Next step
        </button>
      </div>
    </>
  );
};

const Step3 = ({ targetAudienceSetter, socialMedias }) => {
  const [targetAudienceKnown, setTargetAudienceKnown] = useState(false);
  const [targetAudience, setTargetAudience] = useState("");

  const [socialMediaContentKnown, setSocialMediaContentKnown] = useState(false);
  const [socialMediaContent, setSocialMediaContent] = useState("");

  const [additionalNotes, setAdditionalNotes] = useState("");

  return (
    <div className="flex flex-col justify-start w-full gap-y-3">
      {/* Target audience */}
      <div className="text-[#080a0b] text-base text-center font-bold leading-5">¿Conoces a tu público objetivo?</div>
      {/* Yes/No */}
      <div className="flex flex-row items-center w-full justify-evenly gap-x-2">
        <label for="targetAudienceKnown_Yes" className="flex flex-row items-center justify-center px-4 py-2 gap-x-2">
          <input id="targetAudienceKnown_Yes" className="accent-[#ff1414] text-base font-bold leading-5" type="radio" name="target-audience" value="yes" onClick={() => setTargetAudienceKnown(true)} /> <span className="text-[#080a0b] text-base font-bold leading-5">Sí</span>
        </label>
        <label for="targetAudienceKnown_No" className="flex flex-row items-center justify-center px-4 py-2 gap-x-2">
          <input id="targetAudienceKnown_No" className="accent-[#ff1414] text-base font-bold leading-5" type="radio" name="target-audience" value="no" onClick={() => setTargetAudienceKnown(false)} />
          <span className="text-[#080a0b] text-base font-bold leading-5">No</span>
        </label>
      </div>
      {/* Target audience */}
      <textarea
        // Switch disabled style if target audience is not known
        className={`transition-all duration-75 w-full h-24 p-3 border rounded-3xl shadow-sm leading-tight ${!targetAudienceKnown ? "bg-[#e6e6e6]" : ""}`}
        // TODO: Fix placeholder question
        placeholder={targetAudienceKnown ? "Describe a tu público objetivo" : ""}
        disabled={!targetAudienceKnown}
        value={targetAudience}
        onChange={(e) => setTargetAudience(e.target.value)}
      />

      {/* Audience content by social media */}
      {/* TODO: prefill because it's a boring question */}
      <div className="text-[#080a0b] text-base text-center font-bold leading-5">¿Sabes qué contenido le gusta a tu público?</div>
      {/* Yes/No */}
      <div className="flex flex-row items-center w-full justify-evenly gap-x-2">
        <label for="socialMediaContentKnown_Yes" className="flex flex-row items-center justify-center px-4 py-2 gap-x-2">
          <input id="socialMediaContentKnown_Yes" className="accent-[#ff1414] text-base font-bold leading-5" type="radio" name="target-audience" value="yes" onClick={() => setSocialMediaContentKnown(true)} />
          <span className="text-[#080a0b] text-base font-bold leading-5">Sí</span>
        </label>
        <label for="socialMediaContentKnown_No" className="flex flex-row items-center justify-center px-4 py-2 gap-x-2">
          <input id="socialMediaContentKnown_No" className="accent-[#ff1414] text-base font-bold leading-5" type="radio" name="target-audience" value="no" onClick={() => setSocialMediaContentKnown(false)} />
          <span className="text-[#080a0b] text-base font-bold leading-5">No</span>
        </label>
      </div>
      {/* Target audience */}
      <textarea
        // Switch disabled style if the scoial media content is not known
        className={`transition-all duration-75 w-full h-24 p-3 border rounded-3xl shadow-sm leading-tight ${!socialMediaContentKnown ? "bg-[#e6e6e6]" : ""}`}
        // TODO: Fix placeholder question
        placeholder={socialMediaContentKnown ? "Describe a tu público objetivo" : ""}
        disabled={!socialMediaContentKnown}
        value={socialMediaContent}
        onChange={(e) => setSocialMediaContent(e.target.value)}
      />

      {/* Additional notes title */}
      <div className="text-[#080a0b] text-base text-center font-bold leading-5 mt-5">¿Algo más que deberíamos saber?</div>
      {/* Additional notes textarea */}
      <textarea className="w-full h-24 p-3 leading-tight border shadow-sm rounded-3xl" placeholder="Escribe aquí cualquier cosa que creas que pueda ser relevante para tu campaña" value={additionalNotes} onChange={(e) => setAdditionalNotes(e.target.value)} />
      {/* Next step button on the bottom of the screen */}
      <div className="absolute left-0 right-0 flex flex-row items-center justify-center w-full px-4 mx-auto bottom-2">
        <button className="w-full py-2 bg-[#CC1010] rounded-3xl shadow-md" onClick={() => targetAudienceSetter(targetAudienceKnown, targetAudience, socialMediaContentKnown, targetAudience, additionalNotes)}>
          Next step
        </button>
      </div>
    </div>
  );
};

const Step4 = ({ }) => {
  return (
    // Attempt to connect with social media accounts
    <div className="flex flex-col justify-center w-full h-full gap-y-3">
      {/* Title */}
      <div className="text-[#080a0b] text-base text-center font-bold leading-5">Conectando con...</div>
      {/* Social media icon */}
      <div className="bg-gray-200 rounded-3xl aspect-square"></div>
      {/* Social media name */}
      <div className="text-[#080a0b] text-base text-center font-bold leading-5">Facebook</div>
      {/* Connect button */}
      <div className="flex flex-row items-center justify-center w-full gap-x-2">
        <button className="w-1/2 py-2 bg-[#FFd8d8] rounded-3xl shadow-md">Conectar</button>
      </div>
      {/* Next step button on the bottom of the screen */}
      <div className="absolute left-0 right-0 flex flex-row items-center justify-center w-full px-4 mx-auto bottom-2">
        <button className="w-full py-2 bg-[#CC1010] rounded-3xl shadow-md">Next step</button>
      </div>
    </div>
  );
};
export default Create;
